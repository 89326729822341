import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '~components/Link';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { getNavigationPageLink } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';
import AnalyticsTracking, { ANALYTIC_EVENT } from '~utils/analytics-tracking';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#282828',
    padding: '24px 0px',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#1D91FF',
      padding: '12px 16px'
    }
  },
  text: {
    textAlign: 'center',
    width: '100%',
    color: '#F1F1F1',
    marginTop: 0,
    marginBottom: 0,
    fontFamily: 'Inter',
    fontSize: '18px',
    lineHeight: '140%',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      color: '#FFFFFF'
    }
  }
}));

const LINK_ELEMENT_ID = 'join-top-banner';

const MarketingSecondaryHeader = () => {
  const classes = useStyles();
  const { isMarketingSite } = useConversionFramework();
  const { marketingUser } = useUserContext().session;

  useEffect(() => {
    if (!marketingUser?.isSubscriber && isMarketingSite) {
      AnalyticsTracking.makeLinksTrackable(`#${LINK_ELEMENT_ID}`, ANALYTIC_EVENT.BANNER_CLICK, {
        Banner: 'Top Banner',
        UID: 'top-banner',
        'Page Path': typeof window !== 'undefined' && window.location.pathname
      });
    }
  }, [marketingUser?.isSubscriber, isMarketingSite]);

  return (
    <Link
      to={
        marketingUser?.isSubscriber
          ? getNavigationPageLink('/', isMarketingSite)
          : getNavigationPageLink('/subscribe', isMarketingSite)
      }
      underline="none"
      id={LINK_ELEMENT_ID}
    >
      <div className={classes.container}>
        <p className={classes.text}>
          Join 180k users across the globe. Gain unlimited access to 1,100+ videos, podcasts,
          articles and more.
        </p>
      </div>
    </Link>
  );
};

export default MarketingSecondaryHeader;
